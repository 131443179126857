/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A few years ago, Prismatic CEO and co-founder Michael Zuercher noticed a need for B2B software companies to have more time and energy to dedicate to their specialty, as many were spending more and more of their time on research and development-related integrations, which in turn meant these companies had less time to focus on their core product. Zuercher formed Prismatic to solve that problem and, after a year and a half of R&D, is ready to launch."), "\n", React.createElement(_components.p, null, "Read the full story on ", React.createElement(_components.a, {
    href: "https://siliconprairienews.com/2021/03/sioux-falls-b2b-software-integration-developer-prismatic-launches-today/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Silicon Prairie News"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
